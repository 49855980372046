
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { Column } from '@/models/components/tables/Column'
import { ColumnFields, OnboardingStageEnum, tracingActions, TracingStage, TracingState, TracingTags } from '@/models/components/tables/enums'
import { StaffTracingResponse } from '@/models/tracing-staff/classes/StaffTracingResponse'
import { formatDate } from '@/utils/formatDate'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    staffTracing: {
      type: StaffTracingResponse,
      required: true
    },
    showCol: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.Person },
      { header: 'Curso', field: ColumnFields.CoursePlan },
      { header: 'Comisión', field: ColumnFields.Course },
      { header: 'RTG Semanal', field: ColumnFields.TutorsRTGWeek },
      { header: 'Tags mas votados', field: ColumnFields.TracingTags },
      { header: 'Informe', field: ColumnFields.CoordinatorReport }
    ]
    return {
      columns,
      ColumnFields,
      OnboardingStageEnum,
      TracingState,
      formatDate
    }
  }
})
